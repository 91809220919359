import React, { useState, useEffect } from "react"
import s from "./Create.module.scss"
import { useSelector, useDispatch } from "react-redux"
import { words } from "../../words"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { Input } from "../../components/common/input/Input.jsx"
import { Button } from "../../components/common/button/Button"
import { Card } from "../../components/common/card/Card"

import { setState, setPack } from "../../ducks/createSlice"

export const Tabs = ({ active, onNext, onBack, packs, gameId }) => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.create)
  const [coppied, setCoppied] = useState(false)
  const [isLive, setIsLive] = useState(true)

  useEffect(() => {
    if (coppied) {
      setTimeout(() => {
        setCoppied(old => !old)
      }, 500)
    }
  }, [coppied])

  useEffect(() => {
    if (window.location.href.indexOf("tennents.co.uk") > -1) {
      setIsLive(true)
    } else {
      setIsLive(false)
    }
  }, [])

  let livePacks = packs
  if (isLive == true) {
    livePacks = livePacks.filter(item => item.selectable)
  }

  return (
    <div className={s.TabWrapper}>
      {active == 0 && (
        <>
          <h3>Let's get the details.</h3>
          <p>Enter the time and date of the Quiz along with a catchy title.</p>
          <div className={s.TabWrapper__datetime}>
            <Input
              label={"Date"}
              type="date"
              value={data.date}
              onChange={val => {
                dispatch(
                  setState({
                    ...data,
                    date: val,
                  })
                )
              }}
            />

            <Input
              label={"Time"}
              type="time"
              value={data.time}
              onChange={val => {
                dispatch(
                  setState({
                    ...data,
                    time: val,
                  })
                )
              }}
            />
          </div>
          <div className={s.TabWrapper__name}>
            <Input
              value={data.title}
              label={"Quiz Name"}
              onChange={val => {
                if (words.some(v => val.includes(v))) {
                  alert("Sorry, we can't accept your quiz name")
                  setState({
                    ...data,
                    title: "",
                  })
                  return
                }

                dispatch(
                  setState({
                    ...data,
                    title: val,
                  })
                )
              }}
            />
          </div>
          <Button
            onClick={() => {
              onNext()
            }}
            className={s.NextButton}
          >
            <span>Next Step</span>
          </Button>
        </>
      )}

      {active == 1 && (
        <>
          <h3>Now for the fun bit, pick your packs</h3>
          <p>
            These will be the rounds in your quiz.
            <br />
            Choose any 5, in the order you would like them in the quiz...
          </p>

          <div className={s.Packs}>
            {livePacks.map(pack => {
              return (
                <Card
                  pack={pack}
                  selected={data.packs.indexOf(pack.contentful_id) > -1}
                  selectedVal={data.packs.indexOf(pack.contentful_id)}
                  onClick={() => {
                    dispatch(setPack(pack.contentful_id))
                  }}
                />
              )
            })}
          </div>

          <div className={s.TabWrapper__buttons}>
            <Button
              className={s.NextButton}
              onClick={() => {
                onBack()
              }}
              secondary
            >
              <span>Go Back</span>
            </Button>
            {data.packs.length > 0 && (
              <Button
                className={s.NextButton}
                onClick={() => {
                  onNext()
                }}
              >
                <span>Next Step</span>
              </Button>
            )}
          </div>
        </>
      )}

      {active == 3 && (
        <>
          <h3>Hints & Tips</h3>
          <p>A few things to make your quiz go smoothly.</p>
          <div className={s.Hints}>
            <ul>
              <li>
                Pick a video platform to host your Quiz. Some great options are{" "}
                <a href="https://zoom.us/" target="_blank">
                  Zoom
                </a>
                ,{" "}
                <a href="https://hangouts.google.com/" target="_blank">
                  Google Hangouts
                </a>{" "}
                or{" "}
                <a href="whereby.com/" target="_blank">
                  Whereby
                </a>
              </li>
              <li>
                Load up the quiz on a desktop or laptop. This makes sure that
                everyone can see the quiz clearly.
              </li>
              <li>
                Use the screen share function to share the quiz with your pals.
              </li>
              <li>
                Don't be afraid to mute. When reading out the questions, mute
                the other players so you can be heard clearly.
              </li>
              <li>
                Share pictures of you playing with us on Instagram{" "}
                <a
                  target="_blank"
                  href="https://www.instagram.com/TennentsLager/"
                >
                  @TennentsLager
                </a>
                !
              </li>
            </ul>
          </div>
          <div className={s.TabWrapper__buttons}>
            <Button
              onClick={() => {
                onBack()
              }}
              secondary
            >
              <span>Go Back</span>
            </Button>
            <Button
              onClick={() => {
                window.open(`https://pintless.tennents.co.uk/play/${gameId}`)
              }}
            >
              <span>Launch Quiz</span>
            </Button>
          </div>
        </>
      )}

      {active == 2 && (
        <>
          <h3>Quiz Link</h3>
          <p
            style={{
              maxWidth: 500,
              margin: "0 auto",
            }}
          >
            This is the link to your game. Share it with your other players so
            everyone can join in.
          </p>

          <div className={`${s.Copy} ${coppied && s.Copy__active}`}>
            <div className={s.Copy__inner}>
              <span>Your game link</span>
              <a
                href={`https://pintless.tennents.co.uk/play/${gameId}`}
                target="_blank"
              >
                {`https://pintless.tennents.co.uk/play/${gameId}`}
              </a>
            </div>
            <CopyToClipboard
              text={`https://pintless.tennents.co.uk/play/${gameId}`}
              onCopy={() => setCoppied(true)}
            >
              <div className={s.Copy__btn}>
                <span>Copy</span>
              </div>
            </CopyToClipboard>
          </div>

          <p
            style={{
              margin: "0 auto",
            }}
          >
            Use the link below if you want to screen share the quiz.
          </p>

          <div className={`${s.Copy} ${coppied && s.Copy__active}`}>
            <div className={s.Copy__inner}>
              <span>Screen share link</span>
              <a
                href={`https://pintless.tennents.co.uk/play/${gameId}/p`}
                target="_blank"
              >
                {`https://pintless.tennents.co.uk/play/${gameId}/p`}
              </a>
            </div>
          </div>

          <div className={s.TabWrapper__buttons}>
            <Button
              onClick={() => {
                onBack()
              }}
              secondary
              className={s.NextButton}
            >
              <span>Go Back</span>
            </Button>

            {/* <a
              target="_blank"
              href={`https://pintless.tennents.co.uk/play/${gameId}`}
            >
              <Button className={s.LaunchButton}>
                <span>Launch Quiz</span>
              </Button>
            </a> */}
            <Button
              onClick={() => {
                onNext()
              }}
              className={s.NextButton}
            >
              <span>Get Tips</span>
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

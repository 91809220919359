import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const app = createSlice({
  name: "create",
  initialState: {
    code: null,
    id: null,
    gameId: null,
    title: null,
    date: null,
    time: null,
    packs: [],
    games: [],
  },
  reducers: {
    setState: (state, action) => {
      return { ...state, ...action.payload }
    },
    setPack: (state, action) => {
      let idAlreadyExists = state.packs.indexOf(action.payload) > -1

      if (idAlreadyExists) {
        state.packs = state.packs.filter(x => x != action.payload)
        return state
      } else {
        if (state.packs.length == 5) return
        state.packs.push(action.payload)
        return state
      }
    },
  },
})

export const getGame = data => async dispatch => {
  const gameData = await axios.post(
    `https://pintless.tennents.co.uk/.netlify/functions/game-get`,
    {
      gameId: data.gameId,
    }
  )

  dispatch(setState({ ...gameData.data, code: data.code, gameId: data.gameId }))
}

export const getGames = data => async dispatch => {
  const gamesData = await axios.post(
    `https://pintless.tennents.co.uk/.netlify/functions/games-get`,
    {
      code: data.code,
    }
  )

  dispatch(
    setState({
      games: gamesData,
    })
  )
}

export const { setState, setPack } = app.actions

export default app

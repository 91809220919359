import React from "react"

import s from "./DashHeader.module.scss"

export const DashHeader = ({ children, step }) => {
  return (
    <div className={s.Wrapper}>
      <div className={s.Inner}>
        <p>Step {step}/4</p>
        {children}
      </div>
    </div>
  )
}

import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { navigate } from "gatsby"

const app = createSlice({
  name: "play",
  initialState: {
    rounds: [],
    title: null,
    currentRound: 0,
    currentQuestion: 0,
    endOfAnswers: false,
    teamName: "",
    answers: [],
    teams: [],
    teamId: null,
    isPresenter: false,
  },
  reducers: {
    setState: (state, action) => {
      return { ...state, ...action.payload }
    },
    setAnswer: (state, action) => {
      state.rounds[action.payload.round].questions[
        action.payload.questionNumber
      ].userAnswer = action.payload.answer
      return state
    },
    markAnswer: (state, action) => {
      state.rounds[action.payload.round].questions[
        action.payload.questionNumber
      ].result = action.payload.result
      return state
    },
  },
})

export const nextAnswerQuestion = (
  currentQuestion,
  questionsLength,
  currentRound,
  totalRounds
) => async dispatch => {
  if (currentQuestion < questionsLength - 1) {
    // dispatch(setState({ currentQuestion: currentQuestion + 1 }))
    navigate(`/play/answers/${currentRound}/${currentQuestion + 1}`)
  } else {
    //next round or end of game
    if (currentRound < totalRounds - 1) {
      // dispatch(setState({ currentQuestion: 0, currentRound: currentRound + 1 }))
      navigate(`/play/round/${currentRound + 1}`)

      // navigate(`/play/round`)
    } else {
      //END
      navigate(`/play/results`)
      dispatch(
        setState({ currentQuestion: 0, currentRound: 0, endOfAnswers: true })
      )
    }
    // dispatch(setState({ currentQuestion: 0, currentRound: currentRound + 1 }))
    // navigate(`/game/play/round`)
  }
}

export const prevAnswerQuestion = (
  currentQuestion,
  questionsLength
) => async dispatch => {
  if (currentQuestion > 0) {
    dispatch(setState({ currentQuestion: currentQuestion - 1 }))
  }
}

export const nextQuestion = (
  currentQuestion,
  questionsLength,
  currentRound,
  totalRounds,
  answer
) => async dispatch => {
  dispatch(
    setAnswer({
      questionNumber: parseInt(currentQuestion),
      round: currentRound,
      answer: answer,
    })
  )

  if (currentQuestion < questionsLength - 1) {
    // dispatch(setState({ currentQuestion: currentQuestion + 1 }))
    navigate(`/play/question/${currentRound}/${parseInt(currentQuestion) + 1}`)
  } else {
    //next round or end of game
    // dispatch(setState({ currentQuestion: 0 }))
    // navigate(`/play/answers`)
    navigate(`/play/answers/${currentRound}/0`)
  }
}

export const backRound = currentRound => async dispatch => {
  dispatch(
    setState({
      currentQuestion: 0,
      currentRound: currentRound - 1,
      endOfAnswers: false,
    })
  )
  navigate(`/play/question`)
}

export const prevQuestion = (
  currentQuestion,
  questionsLength,
  currentRound,
  answer
) => async dispatch => {
  dispatch(
    setAnswer({
      questionNumber: currentQuestion,
      round: currentRound,
      answer: answer,
    })
  )
  navigate(`/play/question/${currentRound}/${currentQuestion - 1}`)

  if (currentQuestion > 0) {
    // dispatch(setState({ currentQuestion: currentQuestion - 1 }))
    navigate(`/play/question/${currentRound}/${currentQuestion - 1}`)
  }
}

export const loadGame = gameId => async dispatch => {
  let rounds = []

  const gameData = await axios.post(
    `https://pintless.tennents.co.uk/.netlify/functions/game-get`,
    {
      gameId: gameId,
    }
  )
  const packsData = await axios.get(
    `https://pintless.tennents.co.uk/.netlify/functions/packs-get`
  )

  if (!gameData.data.packs) {
    navigate(`/`)
    return
  }

  gameData.data.packs.map(packId => {
    let pack = packsData.data.filter(x => {
      return x.id == packId
    })
    rounds.push(pack[0])
  })

  rounds = rounds.filter(Boolean)
  rounds.filter(item => item)

  dispatch(
    setState({
      rounds: rounds,
      title: gameData.data.title,
      gameId: gameData.data.gameId,
      currentRound: 0,
      currentQuestion: 0,
      endOfAnswers: false,
    })
  )
}

export const createTeam = data => async dispatch => {
  const gameData = await axios.post(
    `https://pintless.tennents.co.uk/.netlify/functions/team-create`,
    data
  )
  navigate(`/play/intro`)
  dispatch(setState({ teamId: gameData.data.teamId }))
}

export const getTeams = data => async dispatch => {
  const gameData = await axios.post(
    `https://pintless.tennents.co.uk/.netlify/functions/teams-get`,
    data
  )

  gameData.data.map(team => {
    team.score = team.scores.reduce((a, b) => a + b.score, 0)
  })

  gameData.data.sort((a, b) => b.score - a.score)

  dispatch(
    setState({
      teams: gameData.data,
    })
  )
}

export const setScore = ({ data, gameId }) => async dispatch => {
  const setScoreReq = await axios.post(
    `https://pintless.tennents.co.uk/.netlify/functions/score-set`,
    data
  )
  dispatch(
    getTeams({
      gameId: gameId,
    })
  )
}

export const { setState, setAnswer, markAnswer } = app.actions

export default app

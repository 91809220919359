import React from "react"
import moment from "moment"

import s from "./Card.module.scss"

import New from "../../../images/new.png"

export const Card = ({
  pack,
  selected,
  selectedVal,
  onClick,
  intro,
  style,
}) => {
  return (
    <div
      className={s.PackCard}
      onClick={onClick && onClick}
      style={
        pack.color
          ? {
              background: pack.color,
              ...style,
            }
          : {
              background: "#eaeaea",
            }
      }
    >
      <div
        className={s.PackCard__Image}
        style={
          pack.cover
            ? {
                backgroundImage: `url(${pack.cover.fixed.src})`,
              }
            : {}
        }
      ></div>
      <div className={s.PackCard__Details}>
        <p>{pack.title}</p>
        <span>{pack.difficulty}</span>

        {moment(pack.createdAt).isAfter(moment().subtract(7, "days")) &&
          !intro && (
            <div className={s.PackCard__new}>
              <img src={New} />
            </div>
          )}
      </div>

      <div
        className={`${s.PackCard__SelectedCover} ${
          selected && s.PackCard__SelectedCover___active
        }`}
      >
        <div className={s.PackCard__SelectedCover__Tick}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.041 11.885">
            <path
              data-name="Path 1279"
              d="M.707 7.609l2.863 2.863L13.334.708"
              fill="none"
              stroke="#000"
              stroke-width="2"
            />
          </svg>
        </div>
        <p>Round {selectedVal + 1}</p>
      </div>
    </div>
  )
}

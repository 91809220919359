import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"

import { setState, getGame, getGames } from "../../ducks/createSlice"

import Layout from "../../components/main/layout/Layout"
import { DashHeader } from "../../components/common/dashHeader/DashHeader"

import s from "./Landing.module.scss"

export const Landing = ({ packs, code }) => {
  const dispatch = useDispatch()
  const games = useSelector(state => state.create.games)

  useEffect(() => {
    // if (!code) {
    navigate(`/`)
    // } else {
    //   dispatch(
    //     getGames({
    //       code: code,
    //     })
    //   )
    // }
  }, [])

  return (
    <Layout>
      <DashHeader>
        <h2>We’re here to help you stay home and stick together</h2>
      </DashHeader>
    </Layout>
  )
}

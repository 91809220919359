import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"

import { setState, getGame } from "../../ducks/createSlice"

import Layout from "../../components/main/layout/Layout"
import { DashHeader } from "../../components/common/dashHeader/DashHeader"
import { Tabs } from "./tabs"
import { Footer } from "../../components/main/footer/Footer"

import s from "./Create.module.scss"

export const Create = ({ packs, gameId, code }) => {
  const dispatch = useDispatch()
  const reducer = useSelector(state => state.create)
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    if (!code || !gameId) {
      navigate(`/`)
    } else {
      dispatch(
        getGame({
          code: code,
          gameId: gameId,
        })
      )
    }
  }, [])

  const _save = () => {
    axios.post(
      `https://pintless.tennents.co.uk/.netlify/functions/game-update`,
      // `https://pintless-staging.netlify.app/.netlify/functions/game-update`,
      {
        ...reducer,
      }
    )
  }

  return (
    <Layout dash={true}>
      <DashHeader step={currentTab + 1}>
        <h2>Creating your quiz</h2>
      </DashHeader>

      <div className={s.CreateTabs}>
        <div
          className={s.CreateTabs__Progress}
          style={{
            width: `${(currentTab + 1) * 25}%`,
          }}
        ></div>
        <div
          className={`${s.CreateTabs__Tab} ${
            currentTab == 0 && s.CreateTabs__Tab___active
          }`}
        >
          <span>1. Your Game Details</span>
        </div>
        <div
          className={`${s.CreateTabs__Tab} ${
            currentTab == 1 && s.CreateTabs__Tab___active
          }`}
        >
          <span>2. Choose Your Questions</span>
        </div>
        <div
          className={`${s.CreateTabs__Tab} ${
            currentTab == 2 && s.CreateTabs__Tab___active
          }`}
        >
          <span>3. Quiz Link</span>
        </div>
        <div
          className={`${s.CreateTabs__Tab} ${
            currentTab == 3 && s.CreateTabs__Tab___active
          }`}
        >
          <span>4. Hints & Tips</span>
        </div>
      </div>

      <div className={s.Wrapper}>
        <div className={s.Inner}>
          <Tabs
            active={currentTab}
            packs={packs}
            gameId={gameId}
            onNext={() => {
              _save()
              setCurrentTab(currentTab + 1)
            }}
            onBack={() => {
              setCurrentTab(currentTab - 1)
            }}
          />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

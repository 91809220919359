import React from "react"
import { Router } from "@reach/router"
import { Provider } from "react-redux"
import { store } from "../store"
import { Link, graphql } from "gatsby"

import { Live } from "../screens/live"
import { Event } from "../screens/event"
import { Create } from "../screens/Create/create"
import { Landing } from "../screens/Landing/Landing"

import { Play } from "../screens/Play/Play"
import { Intro } from "../screens/Play/Intro"
import { Round } from "../screens/Play/Round"
import { Question } from "../screens/Play/Question"
import { Answers } from "../screens/Play/Answers"

const App = ({ data }) => {
  const packs = data.allContentfulPack.nodes

  return (
    <Provider store={store}>
      <Router basepath="/create">
        <Landing path="/:code" />
        <Create path="/:code/:gameId" packs={packs} />
        {/* <Live path="/live/:gameId" /> */}

        {/* <Round path="/play/round" />
        <Question path="/play/question" />
        <Answers path="/play/answers" />
        <Intro path="/play/:gameId" />
        <Intro path="/play" /> */}

        {/* <Event path="/:gameId" /> */}
        {/* <Fourofour default /> */}
      </Router>
    </Provider>
  )
}
export default App

export const query = graphql`
  query {
    allContentfulPack(sort: { fields: createdAt, order: DESC }) {
      nodes {
        contentful_id
        title
        blurb
        color
        difficulty
        selectable
        createdAt
        cover {
          fixed {
            src
          }
        }
      }
    }
  }
`

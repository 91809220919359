export const words = [
  "$cum",
  "$hit",
  "$hittie",
  "(ock",
  "*c*u*n*t*",
  "*unt",
  "@r$e",
  "@r$e.",
  "@rse",
  "@rsehol",
  "@rsehole",
  "@rses",
  "@unt",
  "@unt!",
  "[unt",
  "[unts",
  "{unt",
  "< unt",
  "<***s",
  "<**t",
  "<**ts",
  "<**t's",
  "<*nt",
  "<.unt",
  "<nuts",
  "<o(k",
  "<o<&nbsp;k",
  "<o<ksu<ka",
  "<o<ksu<ker",
  "<oon",
  "<u&nbsp;nt",
  "<u&nbsp;nts",
  "<u*t",
  "<unt",
  "<unting",
  "<unts",
  "<unt's",
  "<vnt",
  "<vnts",
  "3rse",
  "5h1te",
  "81tch",
  "8itch",
  "a$$",
  "a$$hole",
  "a$$hole$",
  "a$$holes",
  "a***",
  "a**e",
  "a**es",
  "a.rse",
  "a?sehole",
  "a+*hole",
  "ar$e",
  "ar$ehole",
  "ar$hole",
  "ar$holes",
  "ar.se",
  "ar5e",
  "ar5e.",
  "ar5ewipe",
  "ar5h0le",
  "ar5h0les",
  "ars3",
  "arse",
  "arseh0le",
  "arseh0les",
  "arseho",
  "arsehol",
  "arsehole",
  "arseholes",
  "arse-holes",
  "arsewipe",
  "arsh0le",
  "arshole",
  "arsholes",
  "ass hole",
  "ass_hole",
  "ashole",
  "assh0le",
  "assh0les",
  "asshole",
  "assholes",
  "bitch",
  "shiit",
  "b*ll*cks",
  "b*ll*ck's",
  "b*st*&rd",
  "b*st*&rds",
  "b*stards",
  "b.astard",
  "b.ugger",
  "b@ll@cks",
  "b@st@rd",
  "b@st@rds",
  "b0//0cks",
  "b0110ck",
  "b0110cks",
  "b0ll0cks",
  "b0llocks",
  "b1tch",
  "b3llend",
  "backdoor",
  "balls deep",
  "balls deep!",
  "ballsack",
  "ball sack",
  "bas*ards",
  "bastard",
  "bastards",
  "basterd",
  "bastrds",
  "battyboy",
  "baw bag",
  "bawbag",
  "belend",
  "bell.end",
  "bellend",
  "bell-end",
  "bo****ks",
  "bo11ocks",
  "boll0cks",
  "bollocks",
  "bollox",
  "bolocks",
  "bolox",
  "boning",
  "boob",
  "boobs",
  "boobies",
  "brothel",
  "bugger",
  "bull5h1tt1ng",
  "bullsh!t",
  "bumbandit",
  "bum-bandit",
  "bumh0l3",
  "bumh0le",
  "bumhol3",
  "bumhole",
  "bummers",
  "c u n t",
  "c u n t.",
  "c unt",
  "c unt.",
  "c#nt",
  "c&nbsp;u&nbsp;n&nbsp;t",
  "c*&ts",
  "c***",
  "c***s",
  "c***ts",
  "c**k",
  "c**t",
  "c**ts",
  "c**t's",
  "c*nt",
  "c.u.n.t",
  "c.unt",
  "c.untyb.ollocks",
  "c@#t",
  "c@nts",
  "c_u_n_t",
  "c00n",
  "c0ck",
  "c0cksucka",
  "c0cksucker",
  "chinkies",
  "chinky",
  "clucking",
  "co(k",
  "cock",
  "cockfaced",
  "cockh3ad",
  "cockhead",
  "cocksucka",
  "cocksucker",
  "cocksuckers",
  "cocksuckers.",
  "coon",
  "cu*t",
  "cunt",
  "c-u-n-t",
  "cunting",
  "cunts",
  "cunt's",
  "cvnt",
  "cvnts",
  "d**khead",
  "d*ckhead",
  "d*ckheads",
  "d1ck",
  "d1ck!",
  "d1ckh@ed",
  "darkie",
  "darky",
  "dick&nbsp;head",
  "dickhead",
  "dicks",
  "diklik",
  "dippers",
  "dogshit",
  "dumba$$",
  "dumb ass",
  "dumbfuck",
  "dumbfucker",
  "dxxkhead",
  "effin",
  "efkin",
  "ejaculate",
  "ejaculating",
  "er3e",
  "erse",
  "ethnics",
  "f ***",
  "f **k",
  "f a g!",
  "f all",
  "f c u k off.",
  "f ck",
  "f cker",
  "f cking",
  "f right off",
  "f u - c k",
  "f u c k",
  "f u c ked",
  "f uc k",
  "f uc king",
  "f uck",
  "f- u-c-k",
  "f ucking",
  "f!ck",
  "f###",
  "f###ing",
  "f##k",
  "f##king",
  "f#ck",
  "f#cked",
  "f$cks",
  "f%$k",
  "f%$king",
  "f&nbsp;cked",
  "f&nbsp;u&nbsp;c&nbsp;k",
  "f&nbsp;uck",
  "f&nbsp;ucker",
  "f&nbsp;ucking",
  "f()()k",
  "f()()ker",
  "f()()king",
  "f()ck you",
  "f()ckers",
  "f()ckers.",
  "f()ckin",
  "f* *k",
  "f* ck",
  "f*#kin' ",
  "f*&k",
  "f*&k!ng",
  "f*****g",
  "f****d",
  "f***ed",
  "f***in",
  "f***ing",
  "f**ing",
  "f**k",
  "f**ked",
  "f**ker",
  "f**kin",
  "f**king",
  "f**ks",
  "f*c*",
  "f*c*in",
  "f*ck",
  "f*ckin",
  "f*cking",
  "f*k",
  "f*ked",
  "f*ker",
  "f*ks",
  "f*uck",
  "f*uk",
  "f.u.c.k.",
  "f.uck",
  "f@&*ing",
  "f@@@in",
  "f@@@ing",
  "f@@k ",
  "f@ck",
  "f@ckin",
  "f@g",
  "f@gs",
  "f@uk ",
  "f[_]cker",
  "f[_]cking",
  "f^(k",
  "f^^k",
  "f^^ked",
  "f^^ker",
  "f^^king",
  "f^ck",
  "f^cker",
  "f^cking",
  "f__kin",
  "f__king",
  "f<uk",
  "f>>k",
  "f£ck",
  "f00ck",
  "f00cking",
  "f00k",
  "f00ked",
  "f0oked",
  "f3ck",
  "f3cking",
  "faeces",
  "fagg0t",
  "faggits",
  "faggot",
  "faggoty",
  "fanny",
  "fc*king",
  "f'ck",
  "fck&nbsp;ing",
  "fck1ng",
  "fckeud",
  "fckin",
  "fcking",
  "fckw1t",
  "fckwit",
  "f-cuk",
  "fcuked",
  "fcuker",
  "fcukin",
  "fcuking",
  "fcuks",
  "feaces ",
  "feck",
  "feckin",
  "fecking",
  "f---ed",
  "fehck",
  "fehcks",
  "fekking",
  "felch",
  "felched",
  "felching",
  "feltch",
  "feltcher",
  "feltching",
  "fen ian",
  "f'ing",
  "f-ing",
  "f---ing",
  "f--k",
  "fkcin",
  "fking",
  "flange",
  "flucking",
  "fo0ked",
  "f-off",
  "fookd",
  "fooked",
  "fooker",
  "fookin",
  "fookin'",
  "fooking",
  "frigging",
  "frigin",
  "friging",
  "fu&kin",
  "fu&king",
  "fu&nbsp;ck",
  "fu&nbsp;cked",
  "fu&nbsp;cker",
  "fu&nbsp;cking",
  "fu(k",
  "fu(ker",
  "fu(kers",
  "fu**ed",
  "fu*k",
  "fu.ck",
  "fu@k",
  "fu@ker",
  "fu[in",
  "fu^k",
  "fu^ker",
  "fu^king",
  "fu{in",
  "fu~*king",
  "fu< kin",
  "fu<k",
  "f-u-<-k",
  "fu<ked",
  "fu<ker",
  "fu<kin",
  "fu<king",
  "fu<kker",
  "fu<kr",
  "fu<ks",
  "fuc&nbsp;ked",
  "fuc&nbsp;ker",
  "fuc&nbsp;king",
  "fucck",
  "fuck",
  "f'uck",
  "f-uck",
  "f-u-ck",
  "fúck",
  "fúçk",
  "fùck",
  "fûck",
  "fück",
  "ƒuck",
  "fuck&nbsp;ed",
  "fuck&nbsp;ing",
  "fucke&nbsp;d",
  "fucked",
  "fucker",
  "fuckers",
  "fucki&nbsp;ng",
  "fuckin",
  "fucking",
  "fúcking",
  "fuckinghell",
  "fuckk",
  "fucks",
  "fuckup",
  "fuckw1t",
  "fuckwit",
  "fuck-wit",
  "fucw1t",
  "fucwit",
  "fu--ing",
  "fuk",
  "fuc",
  "fukced",
  "fuked",
  "fuker",
  "fukin",
  "fuking",
  "fukk",
  "fukked",
  "fukker",
  "fukkin",
  "fukking",
  "fuks",
  "fukwit",
  "fuukn",
  "funbags",
  "fvck",
  "fvckup",
  "fvck-up",
  "fvckw1t",
  "fvckwit",
  "fvk",
  "gang bang",
  "gangbang",
  "gang-bang",
  "gayboys",
  "gayhole",
  "genitalia",
  "gimp",
  "gob4h1te",
  "gobshite",
  "goldenshower",
  "h0m0",
  "h0mo",
  "hamshank",
  "hitler",
  "homo",
  "homosexual",
  "hooker",
  "hookers",
  "ities",
  "jizz",
  "jungle bunny",
  "junkie",
  "k**t",
  "k@ffir",
  "k@ffirs",
  "k@fir",
  "k@firs",
  "k0k",
  "kaf1r",
  "kaff1r",
  "kaffir",
  "kaffirs",
  "kafir",
  "kafirs",
  "khunt",
  "kiddie fiddler",
  "kiddie fiddling",
  "kiddie-fiddler",
  "kiddie-fiddling",
  "'kiddyfiddler' ",
  "kike",
  "kn* b",
  "kn0b",
  "kn0bhead",
  "knob",
  "knobjockey",
  "knob-jockey",
  "knob jockey",
  "knob&nbsp;head",
  "knobber",
  "knobhead",
  "knobhound",
  "knobend",
  "kraut",
  "kunt",
  "kyke",
  "m.inge",
  "m.otherf.ucker",
  "m0r0n",
  "m0r0ns",
  "m0ron",
  "m0rons",
  "m1nge",
  "marajuana",
  "minge",
  "mof**ker",
  "mof**kers",
  "mofo",
  "mofuccer",
  "mofucker",
  "mofuckers",
  "mofucking",
  "mofukcer",
  "mohterf**ker",
  "mohterf**kers",
  "mohterf*kcer",
  "mohterfuccer",
  "mohterfuccers",
  "mohterfuck",
  "mohterfucker",
  "mohterfuckers",
  "mohterfucking",
  "mohterfucks",
  "mohterfuk",
  "mohterfukcer",
  "mohterfukcers",
  "mohterfuking",
  "mohterfuks",
  "mor0n",
  "mor0ns",
  "moron",
  "morons",
  "moterf**ker",
  "moterfuccer",
  "moterfuck",
  "moterfucker",
  "moterfuckers",
  "moterfucking",
  "moterfucks",
  "mothaf**k",
  "mothaf**ker",
  "mothaf**kers",
  "mothaf**king",
  "mothaf**ks",
  "mothafuccer",
  "mothafuck",
  "mothafucka",
  "motha-fucka",
  "mothafucker",
  "mothafuckers",
  "mothafucking",
  "mothafucks",
  "mother f---ers",
  "motherf**ked",
  "motherf**ker",
  "motherf**kers",
  "motherfuccer",
  "motherfuccers",
  "motherfuck",
  "motherfucked",
  "motherfucker",
  "motherfuckers",
  "motherfucking",
  "motherfucks",
  "motherfukkker",
  "mthaf**ka",
  "mthafucca",
  "mthafuccas",
  "mthafucka",
  "mthafuckas",
  "mthafukca",
  "mthafukcas",
  "muff-diver",
  "muff-diving",
  "muth@fucker",
  "muthaf**k",
  "muthaf**ker",
  "muthaf**kers",
  "muthaf**king",
  "muthaf**ks",
  "muthafuccer",
  "muthafuck",
  "muthafuck@",
  "muthafucka",
  "muthafucker",
  "muthafuckers",
  "muthafucking",
  "muthafucks",
  "muthafukas",
  "n0b",
  "n1gger",
  "n1ggers",
  "nig nog",
  "niga",
  "nigga",
  "niggaz",
  "nigger",
  "niggers",
  "nignog",
  "nig-nog",
  "n1g-n0g",
  "nig-n0g",
  "nipple",
  "n1pple",
  "n1pl3",
  "nob&nbsp;head",
  "nobhead",
  "nonce",
  "orgy",
  "p!ss",
  "p*$$",
  "p***es",
  "p**i",
  "p*ki",
  "p*ss",
  "p.iss-flaps",
  "p@ki",
  "p@kis",
  "p00f",
  "p00fs",
  "p00fter",
  "p00fters",
  "p0of",
  "p155",
  "p88f",
  "p88f!",
  "paddy",
  "paedos",
  "pak1",
  "paki",
  "pakis",
  "peado",
  "penis",
  "penispump",
  "penis-pump",
  "perv",
  "perv.",
  "pervert",
  "perverts",
  "pervs",
  "phanny",
  "phanny.",
  "pheck",
  "phecking",
  "phelching",
  "pheque",
  "phequing",
  "phookin",
  "phuck",
  "phucker",
  "phuckin",
  "phucking",
  "phucks",
  "phuk",
  "phuque",
  "pi$$ed",
  "pikey",
  "pillow biter",
  "pillowbiter",
  "pillow-biter",
  "pills",
  "pimp",
  "p1mp",
  "piss",
  "pis5",
  "piss head",
  "piss off",
  "pissflaps",
  "pl$$",
  "po0f",
  "poff",
  "ponce",
  "ponces!",
  "poostabber",
  "poostabbers",
  "poof",
  "poofs",
  "poofter",
  "porn",
  "p0rn",
  "pr ick",
  "pr!ck ",
  "pr!ck.",
  "pr*(k",
  "pr*(k.",
  "pr*ck",
  "pr*ck.",
  "pr*ck?",
  "pr¡ck",
  "pr1ck",
  "pr1ck!",
  "pr1cks",
  "pr1cks!",
  "pr1k",
  "pr1ks",
  "prick",
  "prik",
  "prossies",
  "prostitute",
  "prostitutes",
  "prozzies",
  "pu$$y",
  "puff",
  "puffs",
  "puffs.",
  "pussy ",
  "queer",
  "queers",
  "quim",
  "quim.",
  "r3tard",
  "r3tarded",
  "raped",
  "rapist",
  "rapists",
  "ret@rd",
  "retard",
  "retarded",
  "retarded.",
  "rim job",
  "rimming",
  "s&m",
  "s&um",
  "s(um",
  "s****.",
  "s**m",
  "s**te",
  "s*um",
  "s.hit",
  "s1ut",
  "sadomasochistic",
  "sado-masochistic",
  "scumbags",
  "scumchester ",
  "scumm",
  "scumm!",
  "sexual",
  "shit",
  "shhit",
  "sh!t",
  "sh!te",
  "sh!tes",
  "sh&te",
  "sh* te",
  "sh***ng",
  "sh*t",
  "sh*thead",
  "sh*theads",
  "sh1t",
  "sh1't",
  "sh1te",
  "sh1thead",
  "sh1theads",
  "shag",
  "shaggers",
  "shat",
  "sheepl0ver",
  "sheep-l0ver",
  "sheepl0vers",
  "sheep-l0vers",
  "sheeplover",
  "sheep-lover",
  "sheep-lovers",
  "sheepshaggers",
  "sheep-shaggers",
  "shirtlifter",
  "shirt-lifter",
  "shirtlifters",
  "shirt-lifters",
  "shit stabber",
  "shit stabbers",
  "shitstabber",
  "shitstabbers",
  "shyte",
  "skank",
  "sluts",
  "slapper",
  "slut",
  "spastic",
  "spaz",
  "spaz.",
  "sperm",
  "spitroast",
  "spit-roast",
  "spit-roasting",
  "spit-roasts",
  "spliff",
  "spunk",
  "spunking",
  "steal",
  "stealing",
  "stole",
  "stolen",
  "suck my balls",
  "suck my balls!",
  "suck my balls!!",
  "suck my cock",
  "suck my cock!",
  "suck my cock!!",
  "t w a t",
  "t wat",
  "t!ts",
  "t&nbsp;w&nbsp;a&nbsp;t",
  "t&nbsp;w&nbsp;a&nbsp;t&nbsp;s ",
  "t**t",
  "t*sser",
  "t*w*a*t",
  "t.wat",
  "t0$$er",
  "t055er",
  "t0ss3r",
  "t0ss3rs",
  "t0sser",
  "t0ssers",
  "ta rt",
  "taigs",
  "taigs.",
  "teabag",
  "teabagging",
  "tea-bagging",
  "testicles",
  "testicules",
  "testes",
  "to$$ers",
  "to**er",
  "to55er",
  "to55ers",
  "tosser",
  "tosser, ",
  "tossers",
  "tossurs ",
  "turd",
  "tvvat",
  "tvvats",
  "twat",
  "tw#t",
  "tw&nbsp;at",
  "tw&t",
  "tw*t",
  "tw@",
  "tw@t",
  "tw@ts",
  "tw_t",
  "tw~t",
  "tw>t",
  "tw4t",
  "tw8t",
  "tw8ts",
  "twa t",
  "twat",
  "t-wat",
  "t-w-a-t.",
  "twats",
  "twatt",
  "twattish",
  "twunt",
  "twunts",
  "udders",
  "vagina",
  "wanker",
  "wankers",
  "w##kers",
  "w#*k",
  "w#nker",
  "w#nkers",
  "w******",
  "w***er",
  "w**kers",
  "w*nker",
  "w*nkers",
  "w.ank",
  "w@@@ers",
  "w@nk",
  "w@nker",
  "w@nkers",
  "w@nking",
  "w@nks",
  "w@nkstain",
  "w0g",
  "w0gs",
  "w4nker!",
  "w4nkers!",
  "wa nker",
  "wan k er",
  "wan k ers",
  "wan ker",
  "wan**r",
  "wan--ers",
  "wank",
  "wanka",
  "wankas",
  "wanke r",
  "wanked",
  "wanker",
  "wankers",
  "wan-kers",
  "wanking",
  "wanks",
  "wank's",
  "wh0re",
  "whanker",
  "whankers",
  "whanking",
  "whatthefuck",
  "whore",
  "whores",
  "wog",
  "xrse",
  "xrseh",
  "xrsehol",
  "xrsehole",
  "xxxhole",
  "yaprick",
  "yid",
  "yidd",
  "yidds",
  "yido",
  "yids",
  "$Cum",
  "$Hit",
  "$Hittie",
  "(Ock",
  "*C*U*N*T*",
  "*Unt",
  "@R$E",
  "@R$E.",
  "@Rse",
  "@Rsehol",
  "@Rsehole",
  "@Rses",
  "@Unt",
  "@Unt!",
  "[Unt",
  "[Unts",
  "{Unt",
  "< Unt",
  "<***S",
  "<**T",
  "<**Ts",
  "<**T'S",
  "<*Nt",
  "<.Unt",
  "<Nuts",
  "<O(K",
  "<O<&Nbsp;K",
  "<O<Ksu<Ka",
  "<O<Ksu<Ker",
  "<Oon",
  "<U&Nbsp;Nt",
  "<U&Nbsp;Nts",
  "<U*T",
  "<Unt",
  "<Unting",
  "<Unts",
  "<Unt'S",
  "<Vnt",
  "<Vnts",
  "3Rse",
  "5H1Te",
  "81Tch",
  "8Itch",
  "A$$",
  "A$$Hole",
  "A$$Hole$",
  "A$$Holes",
  "A***",
  "A**E",
  "A**Es",
  "A.Rse",
  "A?Sehole",
  "A+*Hole",
  "Ar$E",
  "Ar$Ehole",
  "Ar$Hole",
  "Ar$Holes",
  "Ar.Se",
  "Ar5E",
  "Ar5E.",
  "Ar5Ewipe",
  "Ar5H0Le",
  "Ar5H0Les",
  "Ars3",
  "Arse",
  "Arseh0Le",
  "Arseh0Les",
  "Arseho",
  "Arsehol",
  "Arsehole",
  "Arseholes",
  "Arse-Holes",
  "Arsewipe",
  "Arsh0Le",
  "Arshole",
  "Arsholes",
  "Ass Hole",
  "Ass_Hole",
  "Ashole",
  "Assh0Le",
  "Assh0Les",
  "Asshole",
  "Assholes",
  "Bitch",
  "Shiit",
  "B*Ll*Cks",
  "B*Ll*Ck'S",
  "B*St*&Rd",
  "B*St*&Rds",
  "B*Stards",
  "B.Astard",
  "B.Ugger",
  "B@Ll@Cks",
  "B@St@Rd",
  "B@St@Rds",
  "B0//0Cks",
  "B0110Ck",
  "B0110Cks",
  "B0Ll0Cks",
  "B0Llocks",
  "B1Tch",
  "B3Llend",
  "Backdoor",
  "Balls Deep",
  "Balls Deep!",
  "Ballsack",
  "Ball Sack",
  "Bas*Ards",
  "Bastard",
  "Bastards",
  "Basterd",
  "Bastrds",
  "Battyboy",
  "Baw Bag",
  "Bawbag",
  "Belend",
  "Bell.End",
  "Bellend",
  "Bell-End",
  "Bo****Ks",
  "Bo11Ocks",
  "Boll0Cks",
  "Bollocks",
  "Bollox",
  "Bolocks",
  "Bolox",
  "Boning",
  "Boob",
  "Boobs",
  "Boobies",
  "Brothel",
  "Bugger",
  "Bull5H1Tt1Ng",
  "Bullsh!T",
  "Bumbandit",
  "Bum-Bandit",
  "Bumh0L3",
  "Bumh0Le",
  "Bumhol3",
  "Bumhole",
  "Bummers",
  "C U N T",
  "C U N T.",
  "C Unt",
  "C Unt.",
  "C#Nt",
  "C&Nbsp;U&Nbsp;N&Nbsp;T",
  "C*&Ts",
  "C***",
  "C***S",
  "C***Ts",
  "C**K",
  "C**T",
  "C**Ts",
  "C**T'S",
  "C*Nt",
  "C.U.N.T",
  "C.Unt",
  "C.Untyb.Ollocks",
  "C@#T",
  "C@Nts",
  "C_U_N_T",
  "C00N",
  "C0Ck",
  "C0Cksucka",
  "C0Cksucker",
  "Chinkies",
  "Chinky",
  "Clucking",
  "Co(K",
  "Cock",
  "Cockfaced",
  "Cockh3Ad",
  "Cockhead",
  "Cocksucka",
  "Cocksucker",
  "Cocksuckers",
  "Cocksuckers.",
  "Coon",
  "Cu*T",
  "Cunt",
  "C-U-N-T",
  "Cunting",
  "Cunts",
  "Cunt'S",
  "Cvnt",
  "Cvnts",
  "D**Khead",
  "D*Ckhead",
  "D*Ckheads",
  "D1Ck",
  "D1Ck!",
  "D1Ckh@Ed",
  "Darkie",
  "Darky",
  "Dick&Nbsp;Head",
  "Dickhead",
  "Dicks",
  "Diklik",
  "Dippers",
  "Dogshit",
  "Dumba$$",
  "Dumb Ass",
  "Dumbfuck",
  "Dumbfucker",
  "Dxxkhead",
  "Effin",
  "Efkin",
  "Ejaculate",
  "Ejaculating",
  "Er3E",
  "Erse",
  "Ethnics",
  "F ***",
  "F **K",
  "F A G!",
  "F All",
  "F C U K Off.",
  "F Ck",
  "F Cker",
  "F Cking",
  "F Right Off",
  "F U - C K",
  "F U C K",
  "F U C Ked",
  "F Uc K",
  "F Uc King",
  "F Uck",
  "F- U-C-K",
  "F Ucking",
  "F!Ck",
  "F###",
  "F###Ing",
  "F##K",
  "F##King",
  "F#Ck",
  "F#Cked",
  "F$Cks",
  "F%$K",
  "F%$King",
  "F&Nbsp;Cked",
  "F&Nbsp;U&Nbsp;C&Nbsp;K",
  "F&Nbsp;Uck",
  "F&Nbsp;Ucker",
  "F&Nbsp;Ucking",
  "F()()K",
  "F()()Ker",
  "F()()King",
  "F()Ck You",
  "F()Ckers",
  "F()Ckers.",
  "F()Ckin",
  "F* *K",
  "F* Ck",
  "F*#Kin' ",
  "F*&K",
  "F*&K!Ng",
  "F*****G",
  "F****D",
  "F***Ed",
  "F***In",
  "F***Ing",
  "F**Ing",
  "F**K",
  "F**Ked",
  "F**Ker",
  "F**Kin",
  "F**King",
  "F**Ks",
  "F*C*",
  "F*C*In",
  "F*Ck",
  "F*Ckin",
  "F*Cking",
  "F*K",
  "F*Ked",
  "F*Ker",
  "F*Ks",
  "F*Uck",
  "F*Uk",
  "F.U.C.K.",
  "F.Uck",
  "F@&*Ing",
  "F@@@In",
  "F@@@Ing",
  "F@@K ",
  "F@Ck",
  "F@Ckin",
  "F@G",
  "F@Gs",
  "F@Uk ",
  "F[_]Cker",
  "F[_]Cking",
  "F^(K",
  "F^^K",
  "F^^Ked",
  "F^^Ker",
  "F^^King",
  "F^Ck",
  "F^Cker",
  "F^Cking",
  "F__Kin",
  "F__King",
  "F<Uk",
  "F>>K",
  "F£Ck",
  "F00Ck",
  "F00Cking",
  "F00K",
  "F00Ked",
  "F0Oked",
  "F3Ck",
  "F3Cking",
  "Faeces",
  "Fagg0T",
  "Faggits",
  "Faggot",
  "Faggoty",
  "Fanny",
  "Fc*King",
  "F'Ck",
  "Fck&Nbsp;Ing",
  "Fck1Ng",
  "Fckeud",
  "Fckin",
  "Fcking",
  "Fckw1T",
  "Fckwit",
  "F-Cuk",
  "Fcuked",
  "Fcuker",
  "Fcukin",
  "Fcuking",
  "Fcuks",
  "Feaces ",
  "Feck",
  "Feckin",
  "Fecking",
  "F---Ed",
  "Fehck",
  "Fehcks",
  "Fekking",
  "Felch",
  "Felched",
  "Felching",
  "Feltch",
  "Feltcher",
  "Feltching",
  "Fen Ian",
  "F'Ing",
  "F-Ing",
  "F---Ing",
  "F--K",
  "Fkcin",
  "Fking",
  "Flange",
  "Flucking",
  "Fo0Ked",
  "F-Off",
  "Fookd",
  "Fooked",
  "Fooker",
  "Fookin",
  "Fookin'",
  "Fooking",
  "Frigging",
  "Frigin",
  "Friging",
  "Fu&Kin",
  "Fu&King",
  "Fu&Nbsp;Ck",
  "Fu&Nbsp;Cked",
  "Fu&Nbsp;Cker",
  "Fu&Nbsp;Cking",
  "Fu(K",
  "Fu(Ker",
  "Fu(Kers",
  "Fu**Ed",
  "Fu*K",
  "Fu.Ck",
  "Fu@K",
  "Fu@Ker",
  "Fu[In",
  "Fu^K",
  "Fu^Ker",
  "Fu^King",
  "Fu{In",
  "Fu~*King",
  "Fu< Kin",
  "Fu<K",
  "F-U-<-K",
  "Fu<Ked",
  "Fu<Ker",
  "Fu<Kin",
  "Fu<King",
  "Fu<Kker",
  "Fu<Kr",
  "Fu<Ks",
  "Fuc&Nbsp;Ked",
  "Fuc&Nbsp;Ker",
  "Fuc&Nbsp;King",
  "Fucck",
  "Fuck",
  "F'Uck",
  "F-Uck",
  "F-U-Ck",
  "Fúck",
  "Fúçk",
  "Fùck",
  "Fûck",
  "Fück",
  "Ƒuck",
  "Fuck&Nbsp;Ed",
  "Fuck&Nbsp;Ing",
  "Fucke&Nbsp;D",
  "Fucked",
  "Fucker",
  "Fuckers",
  "Fucki&Nbsp;Ng",
  "Fuckin",
  "Fucking",
  "Fúcking",
  "Fuckinghell",
  "Fuckk",
  "Fucks",
  "Fuckup",
  "Fuckw1T",
  "Fuckwit",
  "Fuck-Wit",
  "Fucw1T",
  "Fucwit",
  "Fu--Ing",
  "Fuk",
  "Fuc",
  "Fukced",
  "Fuked",
  "Fuker",
  "Fukin",
  "Fuking",
  "Fukk",
  "Fukked",
  "Fukker",
  "Fukkin",
  "Fukking",
  "Fuks",
  "Fukwit",
  "Fuukn",
  "Funbags",
  "Fvck",
  "Fvckup",
  "Fvck-Up",
  "Fvckw1T",
  "Fvckwit",
  "Fvk",
  "Gang Bang",
  "Gangbang",
  "Gang-Bang",
  "Gayboys",
  "Gayhole",
  "Genitalia",
  "Gimp",
  "Gob4H1Te",
  "Gobshite",
  "Goldenshower",
  "H0M0",
  "H0Mo",
  "Hamshank",
  "Hitler",
  "Homo",
  "Homosexual",
  "Hooker",
  "Hookers",
  "Ities",
  "Jizz",
  "Jungle Bunny",
  "Junkie",
  "K**T",
  "K@Ffir",
  "K@Ffirs",
  "K@Fir",
  "K@Firs",
  "K0K",
  "Kaf1R",
  "Kaff1R",
  "Kaffir",
  "Kaffirs",
  "Kafir",
  "Kafirs",
  "Khunt",
  "Kiddie Fiddler",
  "Kiddie Fiddling",
  "Kiddie-Fiddler",
  "Kiddie-Fiddling",
  "'Kiddyfiddler' ",
  "Kike",
  "Kn* B",
  "Kn0B",
  "Kn0Bhead",
  "Knob",
  "Knobjockey",
  "Knob-Jockey",
  "Knob Jockey",
  "Knob&Nbsp;Head",
  "Knobber",
  "Knobhead",
  "Knobhound",
  "Knobend",
  "Kraut",
  "Kunt",
  "Kyke",
  "M.Inge",
  "M.Otherf.Ucker",
  "M0R0N",
  "M0R0Ns",
  "M0Ron",
  "M0Rons",
  "M1Nge",
  "Marajuana",
  "Minge",
  "Mof**Ker",
  "Mof**Kers",
  "Mofo",
  "Mofuccer",
  "Mofucker",
  "Mofuckers",
  "Mofucking",
  "Mofukcer",
  "Mohterf**Ker",
  "Mohterf**Kers",
  "Mohterf*Kcer",
  "Mohterfuccer",
  "Mohterfuccers",
  "Mohterfuck",
  "Mohterfucker",
  "Mohterfuckers",
  "Mohterfucking",
  "Mohterfucks",
  "Mohterfuk",
  "Mohterfukcer",
  "Mohterfukcers",
  "Mohterfuking",
  "Mohterfuks",
  "Mor0N",
  "Mor0Ns",
  "Moron",
  "Morons",
  "Moterf**Ker",
  "Moterfuccer",
  "Moterfuck",
  "Moterfucker",
  "Moterfuckers",
  "Moterfucking",
  "Moterfucks",
  "Mothaf**K",
  "Mothaf**Ker",
  "Mothaf**Kers",
  "Mothaf**King",
  "Mothaf**Ks",
  "Mothafuccer",
  "Mothafuck",
  "Mothafucka",
  "Motha-Fucka",
  "Mothafucker",
  "Mothafuckers",
  "Mothafucking",
  "Mothafucks",
  "Mother F---Ers",
  "Motherf**Ked",
  "Motherf**Ker",
  "Motherf**Kers",
  "Motherfuccer",
  "Motherfuccers",
  "Motherfuck",
  "Motherfucked",
  "Motherfucker",
  "Motherfuckers",
  "Motherfucking",
  "Motherfucks",
  "Motherfukkker",
  "Mthaf**Ka",
  "Mthafucca",
  "Mthafuccas",
  "Mthafucka",
  "Mthafuckas",
  "Mthafukca",
  "Mthafukcas",
  "Muff-Diver",
  "Muff-Diving",
  "Muth@Fucker",
  "Muthaf**K",
  "Muthaf**Ker",
  "Muthaf**Kers",
  "Muthaf**King",
  "Muthaf**Ks",
  "Muthafuccer",
  "Muthafuck",
  "Muthafuck@",
  "Muthafucka",
  "Muthafucker",
  "Muthafuckers",
  "Muthafucking",
  "Muthafucks",
  "Muthafukas",
  "N0B",
  "N1Gger",
  "N1Ggers",
  "Nig Nog",
  "Niga",
  "Nigga",
  "Niggaz",
  "Nigger",
  "Niggers",
  "Nignog",
  "Nig-Nog",
  "N1G-N0G",
  "Nig-N0G",
  "Nipple",
  "N1Pple",
  "N1Pl3",
  "Nob&Nbsp;Head",
  "Nobhead",
  "Nonce",
  "Orgy",
  "P!Ss",
  "P*$$",
  "P***Es",
  "P**I",
  "P*Ki",
  "P*Ss",
  "P.Iss-Flaps",
  "P@Ki",
  "P@Kis",
  "P00F",
  "P00Fs",
  "P00Fter",
  "P00Fters",
  "P0Of",
  "P155",
  "P88F",
  "P88F!",
  "Paddy",
  "Paedos",
  "Pak1",
  "Paki",
  "Pakis",
  "Peado",
  "Penis",
  "Penispump",
  "Penis-Pump",
  "Perv",
  "Perv.",
  "Pervert",
  "Perverts",
  "Pervs",
  "Phanny",
  "Phanny.",
  "Pheck",
  "Phecking",
  "Phelching",
  "Pheque",
  "Phequing",
  "Phookin",
  "Phuck",
  "Phucker",
  "Phuckin",
  "Phucking",
  "Phucks",
  "Phuk",
  "Phuque",
  "Pi$$Ed",
  "Pikey",
  "Pillow Biter",
  "Pillowbiter",
  "Pillow-Biter",
  "Pills",
  "Pimp",
  "P1Mp",
  "Piss",
  "Pis5",
  "Piss Head",
  "Piss Off",
  "Pissflaps",
  "Pl$$",
  "Po0F",
  "Poff",
  "Ponce",
  "Ponces!",
  "Poostabber",
  "Poostabbers",
  "Poof",
  "Poofs",
  "Poofter",
  "Porn",
  "P0Rn",
  "Pr Ick",
  "Pr!Ck ",
  "Pr!Ck.",
  "Pr*(K",
  "Pr*(K.",
  "Pr*Ck",
  "Pr*Ck.",
  "Pr*Ck?",
  "Pr¡Ck",
  "Pr1Ck",
  "Pr1Ck!",
  "Pr1Cks",
  "Pr1Cks!",
  "Pr1K",
  "Pr1Ks",
  "Prick",
  "Prik",
  "Prossies",
  "Prostitute",
  "Prostitutes",
  "Prozzies",
  "Pu$$Y",
  "Puff",
  "Puffs",
  "Puffs.",
  "Pussy ",
  "Queer",
  "Queers",
  "Quim",
  "Quim.",
  "R3Tard",
  "R3Tarded",
  "Raped",
  "Rapist",
  "Rapists",
  "Ret@Rd",
  "Retard",
  "Retarded",
  "Retarded.",
  "Rim Job",
  "Rimming",
  "S&M",
  "S&Um",
  "S(Um",
  "S****.",
  "S**M",
  "S**Te",
  "S*Um",
  "S.Hit",
  "S1Ut",
  "Sadomasochistic",
  "Sado-Masochistic",
  "Scumbags",
  "Scumchester ",
  "Scumm",
  "Scumm!",
  "Sexual",
  "Shit",
  "Shhit",
  "Sh!T",
  "Sh!Te",
  "Sh!Tes",
  "Sh&Te",
  "Sh* Te",
  "Sh***Ng",
  "Sh*T",
  "Sh*Thead",
  "Sh*Theads",
  "Sh1T",
  "Sh1'T",
  "Sh1Te",
  "Sh1Thead",
  "Sh1Theads",
  "Shag",
  "Shaggers",
  "Shat",
  "Sheepl0Ver",
  "Sheep-L0Ver",
  "Sheepl0Vers",
  "Sheep-L0Vers",
  "Sheeplover",
  "Sheep-Lover",
  "Sheep-Lovers",
  "Sheepshaggers",
  "Sheep-Shaggers",
  "Shirtlifter",
  "Shirt-Lifter",
  "Shirtlifters",
  "Shirt-Lifters",
  "Shit Stabber",
  "Shit Stabbers",
  "Shitstabber",
  "Shitstabbers",
  "Shyte",
  "Skank",
  "Sluts",
  "Slapper",
  "Slut",
  "Spastic",
  "Spaz",
  "Spaz.",
  "Sperm",
  "Spitroast",
  "Spit-Roast",
  "Spit-Roasting",
  "Spit-Roasts",
  "Spliff",
  "Spunk",
  "Spunking",
  "Steal",
  "Stealing",
  "Stole",
  "Stolen",
  "Suck My Balls",
  "Suck My Balls!",
  "Suck My Balls!!",
  "Suck My Cock",
  "Suck My Cock!",
  "Suck My Cock!!",
  "T W A T",
  "T Wat",
  "T!Ts",
  "T&Nbsp;W&Nbsp;A&Nbsp;T",
  "T&Nbsp;W&Nbsp;A&Nbsp;T&Nbsp;S ",
  "T**T",
  "T*Sser",
  "T*W*A*T",
  "T.Wat",
  "T0$$Er",
  "T055Er",
  "T0Ss3R",
  "T0Ss3Rs",
  "T0Sser",
  "T0Ssers",
  "Ta Rt",
  "Taigs",
  "Taigs.",
  "Teabag",
  "Teabagging",
  "Tea-Bagging",
  "Testicles",
  "Testicules",
  "Testes",
  "To$$Ers",
  "To**Er",
  "To55Er",
  "To55Ers",
  "Tosser",
  "Tosser, ",
  "Tossers",
  "Tossurs ",
  "Turd",
  "Tvvat",
  "Tvvats",
  "Twat",
  "Tw#T",
  "Tw&Nbsp;At",
  "Tw&T",
  "Tw*T",
  "Tw@",
  "Tw@T",
  "Tw@Ts",
  "Tw_T",
  "Tw~T",
  "Tw>T",
  "Tw4T",
  "Tw8T",
  "Tw8Ts",
  "Twa T",
  "Twat",
  "T-Wat",
  "T-W-A-T.",
  "Twats",
  "Twatt",
  "Twattish",
  "Twunt",
  "Twunts",
  "Udders",
  "Vagina",
  "Wanker",
  "Wankers",
  "W##Kers",
  "W#*K",
  "W#Nker",
  "W#Nkers",
  "W******",
  "W***Er",
  "W**Kers",
  "W*Nker",
  "W*Nkers",
  "W.Ank",
  "W@@@Ers",
  "W@Nk",
  "W@Nker",
  "W@Nkers",
  "W@Nking",
  "W@Nks",
  "W@Nkstain",
  "W0G",
  "W0Gs",
  "W4Nker!",
  "W4Nkers!",
  "Wa Nker",
  "Wan K Er",
  "Wan K Ers",
  "Wan Ker",
  "Wan**R",
  "Wan--Ers",
  "Wank",
  "Wanka",
  "Wankas",
  "Wanke R",
  "Wanked",
  "Wanker",
  "Wankers",
  "Wan-Kers",
  "Wanking",
  "Wanks",
  "Wank'S",
  "Wh0Re",
  "Whanker",
  "Whankers",
  "Whanking",
  "Whatthefuck",
  "Whore",
  "Whores",
  "Wog",
  "Xrse",
  "Xrseh",
  "Xrsehol",
  "Xrsehole",
  "Xxxhole",
  "Yaprick",
  "Yid",
  "Yidd",
  "Yidds",
  "Yido",
  "Yids",
  "$CUM",
  "$HIT",
  "$HITTIE",
  "(OCK",
  "*C*U*N*T*",
  "*UNT",
  "@R$E",
  "@R$E.",
  "@RSE",
  "@RSEHOL",
  "@RSEHOLE",
  "@RSES",
  "@UNT",
  "@UNT!",
  "[UNT",
  "[UNTS",
  "{UNT",
  "< UNT",
  "<***S",
  "<**T",
  "<**TS",
  "<**T'S",
  "<*NT",
  "<.UNT",
  "<NUTS",
  "<O(K",
  "<O<&NBSP;K",
  "<O<KSU<KA",
  "<O<KSU<KER",
  "<OON",
  "<U&NBSP;NT",
  "<U&NBSP;NTS",
  "<U*T",
  "<UNT",
  "<UNTING",
  "<UNTS",
  "<UNT'S",
  "<VNT",
  "<VNTS",
  "3RSE",
  "5H1TE",
  "81TCH",
  "8ITCH",
  "A$$",
  "A$$HOLE",
  "A$$HOLE$",
  "A$$HOLES",
  "A***",
  "A**E",
  "A**ES",
  "A.RSE",
  "A?SEHOLE",
  "A+*HOLE",
  "AR$E",
  "AR$EHOLE",
  "AR$HOLE",
  "AR$HOLES",
  "AR.SE",
  "AR5E",
  "AR5E.",
  "AR5EWIPE",
  "AR5H0LE",
  "AR5H0LES",
  "ARS3",
  "ARSE",
  "ARSEH0LE",
  "ARSEH0LES",
  "ARSEHO",
  "ARSEHOL",
  "ARSEHOLE",
  "ARSEHOLES",
  "ARSE-HOLES",
  "ARSEWIPE",
  "ARSH0LE",
  "ARSHOLE",
  "ARSHOLES",
  "ASS HOLE",
  "ASS_HOLE",
  "ASHOLE",
  "ASSH0LE",
  "ASSH0LES",
  "ASSHOLE",
  "ASSHOLES",
  "BITCH",
  "SHIIT",
  "B*LL*CKS",
  "B*LL*CK'S",
  "B*ST*&RD",
  "B*ST*&RDS",
  "B*STARDS",
  "B.ASTARD",
  "B.UGGER",
  "B@LL@CKS",
  "B@ST@RD",
  "B@ST@RDS",
  "B0//0CKS",
  "B0110CK",
  "B0110CKS",
  "B0LL0CKS",
  "B0LLOCKS",
  "B1TCH",
  "B3LLEND",
  "BACKDOOR",
  "BALLS DEEP",
  "BALLS DEEP!",
  "BALLSACK",
  "BALL SACK",
  "BAS*ARDS",
  "BASTARD",
  "BASTARDS",
  "BASTERD",
  "BASTRDS",
  "BATTYBOY",
  "BAW BAG",
  "BAWBAG",
  "BELEND",
  "BELL.END",
  "BELLEND",
  "BELL-END",
  "BO****KS",
  "BO11OCKS",
  "BOLL0CKS",
  "BOLLOCKS",
  "BOLLOX",
  "BOLOCKS",
  "BOLOX",
  "BONING",
  "BOOB",
  "BOOBS",
  "BOOBIES",
  "BROTHEL",
  "BUGGER",
  "BULL5H1TT1NG",
  "BULLSH!T",
  "BUMBANDIT",
  "BUM-BANDIT",
  "BUMH0L3",
  "BUMH0LE",
  "BUMHOL3",
  "BUMHOLE",
  "BUMMERS",
  "C U N T",
  "C U N T.",
  "C UNT",
  "C UNT.",
  "C#NT",
  "C&NBSP;U&NBSP;N&NBSP;T",
  "C*&TS",
  "C***",
  "C***S",
  "C***TS",
  "C**K",
  "C**T",
  "C**TS",
  "C**T'S",
  "C*NT",
  "C.U.N.T",
  "C.UNT",
  "C.UNTYB.OLLOCKS",
  "C@#T",
  "C@NTS",
  "C_U_N_T",
  "C00N",
  "C0CK",
  "C0CKSUCKA",
  "C0CKSUCKER",
  "CHINKIES",
  "CHINKY",
  "CLUCKING",
  "CO(K",
  "COCK",
  "COCKFACED",
  "COCKH3AD",
  "COCKHEAD",
  "COCKSUCKA",
  "COCKSUCKER",
  "COCKSUCKERS",
  "COCKSUCKERS.",
  "COON",
  "CU*T",
  "CUNT",
  "C-U-N-T",
  "CUNTING",
  "CUNTS",
  "CUNT'S",
  "CVNT",
  "CVNTS",
  "D**KHEAD",
  "D*CKHEAD",
  "D*CKHEADS",
  "D1CK",
  "D1CK!",
  "D1CKH@ED",
  "DARKIE",
  "DARKY",
  "DICK&NBSP;HEAD",
  "DICKHEAD",
  "DICKS",
  "DIKLIK",
  "DIPPERS",
  "DOGSHIT",
  "DUMBA$$",
  "DUMB ASS",
  "DUMBFUCK",
  "DUMBFUCKER",
  "DXXKHEAD",
  "EFFIN",
  "EFKIN",
  "EJACULATE",
  "EJACULATING",
  "ER3E",
  "ERSE",
  "ETHNICS",
  "F ***",
  "F **K",
  "F A G!",
  "F ALL",
  "F C U K OFF.",
  "F CK",
  "F CKER",
  "F CKING",
  "F RIGHT OFF",
  "F U - C K",
  "F U C K",
  "F U C KED",
  "F UC K",
  "F UC KING",
  "F UCK",
  "F- U-C-K",
  "F UCKING",
  "F!CK",
  "F###",
  "F###ING",
  "F##K",
  "F##KING",
  "F#CK",
  "F#CKED",
  "F$CKS",
  "F%$K",
  "F%$KING",
  "F&NBSP;CKED",
  "F&NBSP;U&NBSP;C&NBSP;K",
  "F&NBSP;UCK",
  "F&NBSP;UCKER",
  "F&NBSP;UCKING",
  "F()()K",
  "F()()KER",
  "F()()KING",
  "F()CK YOU",
  "F()CKERS",
  "F()CKERS.",
  "F()CKIN",
  "F* *K",
  "F* CK",
  "F*#KIN' ",
  "F*&K",
  "F*&K!NG",
  "F*****G",
  "F****D",
  "F***ED",
  "F***IN",
  "F***ING",
  "F**ING",
  "F**K",
  "F**KED",
  "F**KER",
  "F**KIN",
  "F**KING",
  "F**KS",
  "F*C*",
  "F*C*IN",
  "F*CK",
  "F*CKIN",
  "F*CKING",
  "F*K",
  "F*KED",
  "F*KER",
  "F*KS",
  "F*UCK",
  "F*UK",
  "F.U.C.K.",
  "F.UCK",
  "F@&*ING",
  "F@@@IN",
  "F@@@ING",
  "F@@K ",
  "F@CK",
  "F@CKIN",
  "F@G",
  "F@GS",
  "F@UK ",
  "F[_]CKER",
  "F[_]CKING",
  "F^(K",
  "F^^K",
  "F^^KED",
  "F^^KER",
  "F^^KING",
  "F^CK",
  "F^CKER",
  "F^CKING",
  "F__KIN",
  "F__KING",
  "F<UK",
  "F>>K",
  "F£CK",
  "F00CK",
  "F00CKING",
  "F00K",
  "F00KED",
  "F0OKED",
  "F3CK",
  "F3CKING",
  "FAECES",
  "FAGG0T",
  "FAGGITS",
  "FAGGOT",
  "FAGGOTY",
  "FANNY",
  "FC*KING",
  "F'CK",
  "FCK&NBSP;ING",
  "FCK1NG",
  "FCKEUD",
  "FCKIN",
  "FCKING",
  "FCKW1T",
  "FCKWIT",
  "F-CUK",
  "FCUKED",
  "FCUKER",
  "FCUKIN",
  "FCUKING",
  "FCUKS",
  "FEACES ",
  "FECK",
  "FECKIN",
  "FECKING",
  "F---ED",
  "FEHCK",
  "FEHCKS",
  "FEKKING",
  "FELCH",
  "FELCHED",
  "FELCHING",
  "FELTCH",
  "FELTCHER",
  "FELTCHING",
  "FEN IAN",
  "F'ING",
  "F-ING",
  "F---ING",
  "F--K",
  "FKCIN",
  "FKING",
  "FLANGE",
  "FLUCKING",
  "FO0KED",
  "F-OFF",
  "FOOKD",
  "FOOKED",
  "FOOKER",
  "FOOKIN",
  "FOOKIN'",
  "FOOKING",
  "FRIGGING",
  "FRIGIN",
  "FRIGING",
  "FU&KIN",
  "FU&KING",
  "FU&NBSP;CK",
  "FU&NBSP;CKED",
  "FU&NBSP;CKER",
  "FU&NBSP;CKING",
  "FU(K",
  "FU(KER",
  "FU(KERS",
  "FU**ED",
  "FU*K",
  "FU.CK",
  "FU@K",
  "FU@KER",
  "FU[IN",
  "FU^K",
  "FU^KER",
  "FU^KING",
  "FU{IN",
  "FU~*KING",
  "FU< KIN",
  "FU<K",
  "F-U-<-K",
  "FU<KED",
  "FU<KER",
  "FU<KIN",
  "FU<KING",
  "FU<KKER",
  "FU<KR",
  "FU<KS",
  "FUC&NBSP;KED",
  "FUC&NBSP;KER",
  "FUC&NBSP;KING",
  "FUCCK",
  "FUCK",
  "F'UCK",
  "F-UCK",
  "F-U-CK",
  "FÚCK",
  "FÚÇK",
  "FÙCK",
  "FÛCK",
  "FÜCK",
  "ƑUCK",
  "FUCK&NBSP;ED",
  "FUCK&NBSP;ING",
  "FUCKE&NBSP;D",
  "FUCKED",
  "FUCKER",
  "FUCKERS",
  "FUCKI&NBSP;NG",
  "FUCKIN",
  "FUCKING",
  "FÚCKING",
  "FUCKINGHELL",
  "FUCKK",
  "FUCKS",
  "FUCKUP",
  "FUCKW1T",
  "FUCKWIT",
  "FUCK-WIT",
  "FUCW1T",
  "FUCWIT",
  "FU--ING",
  "FUK",
  "FUC",
  "FUKCED",
  "FUKED",
  "FUKER",
  "FUKIN",
  "FUKING",
  "FUKK",
  "FUKKED",
  "FUKKER",
  "FUKKIN",
  "FUKKING",
  "FUKS",
  "FUKWIT",
  "FUUKN",
  "FUNBAGS",
  "FVCK",
  "FVCKUP",
  "FVCK-UP",
  "FVCKW1T",
  "FVCKWIT",
  "FVK",
  "GANG BANG",
  "GANGBANG",
  "GANG-BANG",
  "GAYBOYS",
  "GAYHOLE",
  "GENITALIA",
  "GIMP",
  "GOB4H1TE",
  "GOBSHITE",
  "GOLDENSHOWER",
  "H0M0",
  "H0MO",
  "HAMSHANK",
  "HITLER",
  "HOMO",
  "HOMOSEXUAL",
  "HOOKER",
  "HOOKERS",
  "ITIES",
  "JIZZ",
  "JUNGLE BUNNY",
  "JUNKIE",
  "K**T",
  "K@FFIR",
  "K@FFIRS",
  "K@FIR",
  "K@FIRS",
  "K0K",
  "KAF1R",
  "KAFF1R",
  "KAFFIR",
  "KAFFIRS",
  "KAFIR",
  "KAFIRS",
  "KHUNT",
  "KIDDIE FIDDLER",
  "KIDDIE FIDDLING",
  "KIDDIE-FIDDLER",
  "KIDDIE-FIDDLING",
  "'KIDDYFIDDLER' ",
  "KIKE",
  "KN* B",
  "KN0B",
  "KN0BHEAD",
  "KNOB",
  "KNOBJOCKEY",
  "KNOB-JOCKEY",
  "KNOB JOCKEY",
  "KNOB&NBSP;HEAD",
  "KNOBBER",
  "KNOBHEAD",
  "KNOBHOUND",
  "KNOBEND",
  "KRAUT",
  "KUNT",
  "KYKE",
  "M.INGE",
  "M.OTHERF.UCKER",
  "M0R0N",
  "M0R0NS",
  "M0RON",
  "M0RONS",
  "M1NGE",
  "MARAJUANA",
  "MINGE",
  "MOF**KER",
  "MOF**KERS",
  "MOFO",
  "MOFUCCER",
  "MOFUCKER",
  "MOFUCKERS",
  "MOFUCKING",
  "MOFUKCER",
  "MOHTERF**KER",
  "MOHTERF**KERS",
  "MOHTERF*KCER",
  "MOHTERFUCCER",
  "MOHTERFUCCERS",
  "MOHTERFUCK",
  "MOHTERFUCKER",
  "MOHTERFUCKERS",
  "MOHTERFUCKING",
  "MOHTERFUCKS",
  "MOHTERFUK",
  "MOHTERFUKCER",
  "MOHTERFUKCERS",
  "MOHTERFUKING",
  "MOHTERFUKS",
  "MOR0N",
  "MOR0NS",
  "MORON",
  "MORONS",
  "MOTERF**KER",
  "MOTERFUCCER",
  "MOTERFUCK",
  "MOTERFUCKER",
  "MOTERFUCKERS",
  "MOTERFUCKING",
  "MOTERFUCKS",
  "MOTHAF**K",
  "MOTHAF**KER",
  "MOTHAF**KERS",
  "MOTHAF**KING",
  "MOTHAF**KS",
  "MOTHAFUCCER",
  "MOTHAFUCK",
  "MOTHAFUCKA",
  "MOTHA-FUCKA",
  "MOTHAFUCKER",
  "MOTHAFUCKERS",
  "MOTHAFUCKING",
  "MOTHAFUCKS",
  "MOTHER F---ERS",
  "MOTHERF**KED",
  "MOTHERF**KER",
  "MOTHERF**KERS",
  "MOTHERFUCCER",
  "MOTHERFUCCERS",
  "MOTHERFUCK",
  "MOTHERFUCKED",
  "MOTHERFUCKER",
  "MOTHERFUCKERS",
  "MOTHERFUCKING",
  "MOTHERFUCKS",
  "MOTHERFUKKKER",
  "MTHAF**KA",
  "MTHAFUCCA",
  "MTHAFUCCAS",
  "MTHAFUCKA",
  "MTHAFUCKAS",
  "MTHAFUKCA",
  "MTHAFUKCAS",
  "MUFF-DIVER",
  "MUFF-DIVING",
  "MUTH@FUCKER",
  "MUTHAF**K",
  "MUTHAF**KER",
  "MUTHAF**KERS",
  "MUTHAF**KING",
  "MUTHAF**KS",
  "MUTHAFUCCER",
  "MUTHAFUCK",
  "MUTHAFUCK@",
  "MUTHAFUCKA",
  "MUTHAFUCKER",
  "MUTHAFUCKERS",
  "MUTHAFUCKING",
  "MUTHAFUCKS",
  "MUTHAFUKAS",
  "N0B",
  "N1GGER",
  "N1GGERS",
  "NIG NOG",
  "NIGA",
  "NIGGA",
  "NIGGAZ",
  "NIGGER",
  "NIGGERS",
  "NIGNOG",
  "NIG-NOG",
  "N1G-N0G",
  "NIG-N0G",
  "NIPPLE",
  "N1PPLE",
  "N1PL3",
  "NOB&NBSP;HEAD",
  "NOBHEAD",
  "NONCE",
  "ORGY",
  "P!SS",
  "P*$$",
  "P***ES",
  "P**I",
  "P*KI",
  "P*SS",
  "P.ISS-FLAPS",
  "P@KI",
  "P@KIS",
  "P00F",
  "P00FS",
  "P00FTER",
  "P00FTERS",
  "P0OF",
  "P155",
  "P88F",
  "P88F!",
  "PADDY",
  "PAEDOS",
  "PAK1",
  "PAKI",
  "PAKIS",
  "PEADO",
  "PENIS",
  "PENISPUMP",
  "PENIS-PUMP",
  "PERV",
  "PERV.",
  "PERVERT",
  "PERVERTS",
  "PERVS",
  "PHANNY",
  "PHANNY.",
  "PHECK",
  "PHECKING",
  "PHELCHING",
  "PHEQUE",
  "PHEQUING",
  "PHOOKIN",
  "PHUCK",
  "PHUCKER",
  "PHUCKIN",
  "PHUCKING",
  "PHUCKS",
  "PHUK",
  "PHUQUE",
  "PI$$ED",
  "PIKEY",
  "PILLOW BITER",
  "PILLOWBITER",
  "PILLOW-BITER",
  "PILLS",
  "PIMP",
  "P1MP",
  "PISS",
  "PIS5",
  "PISS HEAD",
  "PISS OFF",
  "PISSFLAPS",
  "PL$$",
  "PO0F",
  "POFF",
  "PONCE",
  "PONCES!",
  "POOSTABBER",
  "POOSTABBERS",
  "POOF",
  "POOFS",
  "POOFTER",
  "PORN",
  "P0RN",
  "PR ICK",
  "PR!CK ",
  "PR!CK.",
  "PR*(K",
  "PR*(K.",
  "PR*CK",
  "PR*CK.",
  "PR*CK?",
  "PR¡CK",
  "PR1CK",
  "PR1CK!",
  "PR1CKS",
  "PR1CKS!",
  "PR1K",
  "PR1KS",
  "PRICK",
  "PRIK",
  "PROSSIES",
  "PROSTITUTE",
  "PROSTITUTES",
  "PROZZIES",
  "PU$$Y",
  "PUFF",
  "PUFFS",
  "PUFFS.",
  "PUSSY ",
  "QUEER",
  "QUEERS",
  "QUIM",
  "QUIM.",
  "R3TARD",
  "R3TARDED",
  "RAPED",
  "RAPIST",
  "RAPISTS",
  "RET@RD",
  "RETARD",
  "RETARDED",
  "RETARDED.",
  "RIM JOB",
  "RIMMING",
  "S&M",
  "S&UM",
  "S(UM",
  "S****.",
  "S**M",
  "S**TE",
  "S*UM",
  "S.HIT",
  "S1UT",
  "SADOMASOCHISTIC",
  "SADO-MASOCHISTIC",
  "SCUMBAGS",
  "SCUMCHESTER ",
  "SCUMM",
  "SCUMM!",
  "SEXUAL",
  "SHIT",
  "SHHIT",
  "SH!T",
  "SH!TE",
  "SH!TES",
  "SH&TE",
  "SH* TE",
  "SH***NG",
  "SH*T",
  "SH*THEAD",
  "SH*THEADS",
  "SH1T",
  "SH1'T",
  "SH1TE",
  "SH1THEAD",
  "SH1THEADS",
  "SHAG",
  "SHAGGERS",
  "SHAT",
  "SHEEPL0VER",
  "SHEEP-L0VER",
  "SHEEPL0VERS",
  "SHEEP-L0VERS",
  "SHEEPLOVER",
  "SHEEP-LOVER",
  "SHEEP-LOVERS",
  "SHEEPSHAGGERS",
  "SHEEP-SHAGGERS",
  "SHIRTLIFTER",
  "SHIRT-LIFTER",
  "SHIRTLIFTERS",
  "SHIRT-LIFTERS",
  "SHIT STABBER",
  "SHIT STABBERS",
  "SHITSTABBER",
  "SHITSTABBERS",
  "SHYTE",
  "SKANK",
  "SLUTS",
  "SLAPPER",
  "SLUT",
  "SPASTIC",
  "SPAZ",
  "SPAZ.",
  "SPERM",
  "SPITROAST",
  "SPIT-ROAST",
  "SPIT-ROASTING",
  "SPIT-ROASTS",
  "SPLIFF",
  "SPUNK",
  "SPUNKING",
  "STEAL",
  "STEALING",
  "STOLE",
  "STOLEN",
  "SUCK MY BALLS",
  "SUCK MY BALLS!",
  "SUCK MY BALLS!!",
  "SUCK MY COCK",
  "SUCK MY COCK!",
  "SUCK MY COCK!!",
  "T W A T",
  "T WAT",
  "T!TS",
  "T&NBSP;W&NBSP;A&NBSP;T",
  "T&NBSP;W&NBSP;A&NBSP;T&NBSP;S ",
  "T**T",
  "T*SSER",
  "T*W*A*T",
  "T.WAT",
  "T0$$ER",
  "T055ER",
  "T0SS3R",
  "T0SS3RS",
  "T0SSER",
  "T0SSERS",
  "TA RT",
  "TAIGS",
  "TAIGS.",
  "TEABAG",
  "TEABAGGING",
  "TEA-BAGGING",
  "TESTICLES",
  "TESTICULES",
  "TESTES",
  "TO$$ERS",
  "TO**ER",
  "TO55ER",
  "TO55ERS",
  "TOSSER",
  "TOSSER, ",
  "TOSSERS",
  "TOSSURS ",
  "TURD",
  "TVVAT",
  "TVVATS",
  "TWAT",
  "TW#T",
  "TW&NBSP;AT",
  "TW&T",
  "TW*T",
  "TW@",
  "TW@T",
  "TW@TS",
  "TW_T",
  "TW~T",
  "TW>T",
  "TW4T",
  "TW8T",
  "TW8TS",
  "TWA T",
  "TWAT",
  "T-WAT",
  "T-W-A-T.",
  "TWATS",
  "TWATT",
  "TWATTISH",
  "TWUNT",
  "TWUNTS",
  "UDDERS",
  "VAGINA",
  "WANKER",
  "WANKERS",
  "W##KERS",
  "W#*K",
  "W#NKER",
  "W#NKERS",
  "W******",
  "W***ER",
  "W**KERS",
  "W*NKER",
  "W*NKERS",
  "W.ANK",
  "W@@@ERS",
  "W@NK",
  "W@NKER",
  "W@NKERS",
  "W@NKING",
  "W@NKS",
  "W@NKSTAIN",
  "W0G",
  "W0GS",
  "W4NKER!",
  "W4NKERS!",
  "WA NKER",
  "WAN K ER",
  "WAN K ERS",
  "WAN KER",
  "WAN**R",
  "WAN--ERS",
  "WANK",
  "WANKA",
  "WANKAS",
  "WANKE R",
  "WANKED",
  "WANKER",
  "WANKERS",
  "WAN-KERS",
  "WANKING",
  "WANKS",
  "WANK'S",
  "WATP",
  "W.A.T.P",
  "watp",
  "WH0RE",
  "WHANKER",
  "WHANKERS",
  "WHANKING",
  "WHATTHEFUCK",
  "WHORE",
  "WHORES",
  "WOG",
  "XRSE",
  "XRSEH",
  "XRSEHOL",
  "XRSEHOLE",
  "XXXHOLE",
  "YAPRICK",
  "YID",
  "YIDD",
  "YIDDS",
  "YIDO",
  "YIDS",
]

import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web

import CreateReducer from "./ducks/createSlice"
import PlayReducer from "./ducks/playSlice"

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["play"],
}

const reducers = combineReducers({
  create: CreateReducer.reducer,
  play: PlayReducer.reducer,
})

let persistedReducer
// if (process.env.NODE_ENV == "development") {
//   persistedReducer = persistReducer(persistConfig, reducers)
// } else {
// persistedReducer = reducers
// }

persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
})

// export default store

let persistor = persistStore(store)

export { store, persistor }
